import { PayPalButton } from "react-paypal-button-v2";
import "./App.css";
import { useEffect, useState } from "react";
import Digiunction from "./Images/Digiunciton.png";
// import img from '../public/img/info-1.png'
import img from './Images/info-1.png'
import digi from './Images/Digiunciton.png'
import CurrencyInput from 'react-currency-input-field';
import black from './Images/black.png'
import paypal from './Images/paypalicon.png'
// import paypal from './Images/paypal.png'



// import Paypal from "./Component/PayPal/Paypal";

function App() {
  const [amount, setAmount] = useState("");
  const [selectedValue, setSelectedValue] = useState("USD");
  const [des, setDes] = useState('');
  const [showpay, setShowpay] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    console.log(selectedValue)
  }, [selectedValue])


  const handleAmountChange = (event) => {

    console.log(event.target.value.length, 'e.t.v');

    if (event.target.value.length >= 1) {
      setAmount(event.target.value);
      setIsButtonDisabled(true)
      // console.log(isButtonDisabled,'isbut');
      // setShowpay(true)
    }
    else {
      setAmount('')
      // setShowpay(false)
      setIsButtonDisabled(false)
      console.log(isButtonDisabled, 'isbut');
    }

  };

  useEffect(() => {
    console.log(isButtonDisabled, 'isbut');
  }, [amount, isButtonDisabled])

  // Handle the onChange event of the select element
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    // console.log(event.target.value,'selectedvalue in funciton');
  };


  const handledes = (e) => {
    setDes(e.target.value)
  }

  const move = () => {

    if (amount && amount.length >= 1) {

      setShowpay(true)
      const delay = 1000; // Set the delay time in milliseconds (e.g., 1000ms = 1 second)
      setTimeout(() => {

        const right = document.getElementsByClassName('right')[0]
        right.scrollTo(0, document.body.scrollHeight);
      }, delay);
    }
    else {
      alert('please enter amount')
    }


  }

  const handlePaymentSuccess = (details, data) => {
    console.log('Payment confirmed', details, data);
  };

  const handlePaymentError = (error) => {
    console.log('Payment failed', error);
  };


  // return <Paypal />;
  return (
    <div style={{ backgroundColor: "black" }}>

      <div className="App" style={{ backgroundColor: 'white' }} >

        <div className="container" style={{ backgroundColor: 'white' }} >

          <div className="row" style={{marginTop:'20px'}} >
            <div className="d-flex justify-content-start align-items-center" >
              <img src={black} style={{ width: '240px', height: '100px' }} />
            </div>
            <div className="col-8" ></div>
          </div>

          <div className="row" style={{ marginTop: '-50px' }} >

            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" style={{marginTop:'50px'}}  >
              <img className='img-class' src={img} />
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 right leftcard " style={{ borderRadius: '15px' }} >

              <div className="row" style={{ marginTop: '29px' }} >
                <div className="col-12" >
                  <img src={paypal} />
                </div>

                <div className="col-12" >
                  <h2 style={{ color: 'white' }} >Paypal</h2>
                </div>

              </div>



              <div className="row " style={{ marginTop: '60px' }} >

                <div className="container row-con " >

                  <div className="row" >

                    <div className="col-6 " >
                      <input className="input-f"
                        type="text"
                        placeholder="Enter Amount"
                        value={amount}
                        onChange={handleAmountChange}
                        style={{ '::placeholder': { color: 'red' } }}
                      />

                    </div>

                    <div className="col-6 " >

                      <select
                        name="currency"
                        id="selected"
                        className="input-f"
                        value={selectedValue}
                        onChange={handleSelectChange}
                        style={{ color: 'white', backgroundColor: 'transparent' }}
                      >
                        <option style={{ color: 'blue' }} value="USD">USD</option>
                        {/* <option style={{ color: 'blue' }} value="AUD">AUD</option>
                        <option style={{ color: 'blue' }} value="EUR">EUR</option>
                        <option style={{ color: 'blue' }} value="CAD">CAD</option>
                        <option style={{ color: 'blue' }} value="GBP">GBP</option> */}
                      </select>


                    </div>

                  </div>

                </div>


                {/* ///// */}

                <div className="col-12" >
                  <textarea
                    style={{ '::placeholder': { color: 'red' } }}
                    placeholder="Description" onChange={handledes} value={des} className="input-text" ></textarea>
                </div>

                <div className="row" >

                  <div className="col-2" ></div>

                  <div className="col-8" >

                    {/* ////// */}

                    {amount.length >= 1 ? (
                      <PayPalButton
                        //  style={{marginTop:'100px'}}
                        amount={amount}
                        options={{
                          clientId:
                            "AWQ9sqxpBvgxW9IrbLCdQ9owRrKfaZpTAOXzzr0TAfrKpux-9vi12HLXJCSkraqYLvl5gxrb0DAYHCdf",
                          currency: selectedValue,
                        }}
                        onError={(details, data) => {
                          alert("Transaction Faild " + details.payer.name.given_name);
                        }}
                        style={{
                          shape: "rect",
                          color: "gold",
                          layout: "vertical",
                          label: "paypal",
                          marginTop:'25px'
                        }}
                        // disabled={isButtonDisabled}
                        // onSuccess={handlePaymentSuccess}
                        // onError={handlePaymentError}
                        onSuccess={(details, data) => {
                          alert("Transaction completed by " + details.payer.name.given_name);
                
                          // OPTIONAL: Call your server to save the transaction
                          // return fetch("/paypal-transaction-complete", {
                          //   method: "post",
                          //   body: JSON.stringify({
                          //     orderId: data.orderID
                            // })
                          // });
                        }}
                      />
                    ) : (
                      <p style={{ color: 'white', fontWeight: '500' }} >
                        "Please Enter Button to proceed"
                      </p>
                    )}

                    {/* /////// */}

                  </div>

                  {/* {
                    showpay === false ?
                      <div className="col-12" >
                        <button style={{ marginBottom: '20px', marginLeft: '20px', width: '75%', backgroundColor: 'rgb(139,183,240)', color: 'white', border: 'none', height: '35px' }} onClick={move} >
                          Paypal
                        </button>
                      </div>
                      : ''
                  } */}

                </div>

              </div>

            </div>
          </div>

        </div>


      </div>
    </div>
  );
}

export default App;
